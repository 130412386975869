"use client";
import React, { useState } from "react";
import { HeroItem } from "./HeroItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { HeroActionButtons } from "./HeroActionButtons";
import { HeroSwiperIndicator } from "./HeroSwiperIndicator";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { EffectFade, Pagination } from "swiper";
import { Button } from "../../Basic/Button";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";
import Link from "next/link";
import { ImagePlaceholder } from "../../Basic/Image/types";

export interface Props {
  items: { image: string; imagePlaceholder?: ImagePlaceholder; link: string }[];
}

function Hero({ items }: Props) {
  const [swiperIndex, setSwiperIndex] = useState(0);

  return (
    <div className="relative lg:min-h-[650px] lg:mb-20">
      <div
        className={
          "max-w-7xl box-content w-full mx-auto flex lg:flex-col flex-col-reverse lg:px-12"
        }
      >
        <div
          className={
            "flex flex-col items-start px-5 lg:px-0 lg:w-[38%] lg:pt-14 mb-6 lg:mb-0"
          }
        >
          <HeroSwiperIndicator activeIndex={swiperIndex} items={items} />
          <h1 className="mb-6 lg:mb-9 text-yellow endemic:text-primary">
            AI Curated NFT Marketplace
          </h1>
          <h3 className="leading-10 font-light mb-10 lg:block">
            Featured artist of the week:{" "}
            <Link href="/@matty">
              <b>Matty Furious</b>
            </Link>
          </h3>
          <Button href="/artwork" endIcon={<ArrowRightIcon className="w-4" />}>
            Explore more
          </Button>
        </div>
        <div
          className={
            "relative right-0 top-0 bottom-0 w-full mb-2 lg:mb-0 lg:absolute lg:w-auto lg:left-[43%] lg:pb-0"
          }
        >
          {items?.length ? (
            <Swiper
              slidesPerView={1}
              grabCursor={true}
              loop
              pagination={{
                el: ".custom-swiper-pagination",
                clickable: true,
                bulletClass:
                  "rounded-full h-2 bg-neutral-300 night:bg-neutral-600 endemic:bg-neutral-300/50 inline-block mr-2 pointer swiper-indicator-item w-2",
                bulletActiveClass:
                  "w-4 night:bg-white light:bg-black endemic:!bg-yellow",
              }}
              modules={[EffectFade, Pagination]}
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
              className="lg:mb-20"
              onRealIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
            >
              {items.map((heroItem, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className={"!h-auto pb-[101%] lg:pb-0 lg:min-h-[650px]"}
                  >
                    <Link href={heroItem.link}>
                      <HeroItem item={heroItem} />
                    </Link>
                  </SwiperSlide>
                );
              })}
              <HeroActionButtons />
            </Swiper>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Hero;
