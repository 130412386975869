import { Nft, NftFilterQuery, NftSortQuery } from "./types";
import requests from "./requests";
import { withClientRequest } from "../requestBuilder/withClientRequest";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import { EnrichedObject, EnrichmentProperties } from "../types";
import { enrichItem, exportAddressesByProperties } from "../helpers";

export async function getSortedEnrichedNfts({
  nftIds,
  order,
  orderDirection,
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
}: NftSortQuery) {
  return await withClientRequest(requests.getSortedNfts)({
    nftIds: nftIds,
    order: order,
    orderDirection: orderDirection,
    page,
    pageSize,
  });
}

export async function getLatestEnrichedNfts({
  take = DEFAULT_PAGE_SIZE,
  skip = 0,
  category,
  marketplaces,
  onSale,
}: NftFilterQuery) {
  return await withClientRequest(requests.getLatestNfts)({
    take,
    skip,
    category,
    marketplaces,
    onSale,
  });
}

export async function enrichWithNft<
  T extends { [k: string]: any },
  TPropsToEnrich extends string,
>(array: T[], properties: EnrichmentProperties<T>, includeBurned = false) {
  const listOfAddresses = exportAddressesByProperties(array, properties);
  const nfts = await withClientRequest(requests.getNftsByIdentifiers)(
    listOfAddresses,
    includeBurned,
  );
  const newArray = array.map((item) => {
    const newItem: any = enrichItem(item, properties, nfts, (nft) => nft.id);
    return newItem;
  });

  return newArray as EnrichedObject<T, TPropsToEnrich, Nft>[];
}

const clientRequests = {
  enrichWithNft,
  getLatestEnrichedNfts,
  createDraftNft: withClientRequest(requests.createDraftNft),
  updateDraftNft: withClientRequest(requests.updateDraftNft),
  sendForApproval: withClientRequest(requests.sendForApproval),
  cancelDraftNft: withClientRequest(requests.cancelDraftNft),
  deleteDraftNft: withClientRequest(requests.deleteDraftNft),
  getLatestNfts: withClientRequest(requests.getLatestNfts),
  getNftsForCollection: withClientRequest(requests.getNftsForCollection),
  getNftsForCollections: withClientRequest(requests.getNftsForCollections),
  getNftsByOwner: withClientRequest(requests.getNftsByOwner),
  getNftsByArtist: withClientRequest(requests.getNftsByArtist),
  getNftsByArtists: withClientRequest(requests.getNftsByArtists),
  getPopularNfts: withClientRequest(requests.getPopularNfts),
  getLikedNfts: withClientRequest(requests.getLikedNfts),
  getNftsByIdentifiers: withClientRequest(requests.getNftsByIdentifiers),
  getSingleNft: withClientRequest(requests.getSingleNft),
  getDraftNftsForArtist: withClientRequest(requests.getDraftNftsForArtist),
  getSortedNfts: withClientRequest(requests.getSortedNfts),
  getNftDetails: withClientRequest(requests.getNftDetails),
};

export default clientRequests;
