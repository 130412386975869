"use client";
import Link from "next/link";
import { twMerge } from "tailwind-merge";
import { ImageOrientation } from "@/api/nfts/types";
import { UserLink } from "../../../User/UserLink";
import { getUsersName } from "@/api/users/helpers";
import { EndemicUserWrapper } from "@/app/(components)/Basic/EndemicUserWrapper";
import { BaseProps as ArtworkProps } from "../ArtworkItem";
import ArtworkItemTradeInfo from "../ArtworkItemTradeInfo/ArtworkItemTradeInfo";

export interface Props extends ArtworkProps {
  imageOrientation: ImageOrientation;
}

const ArtworkItemContent = ({
  id,
  name,
  artist,
  imageOrientation,
  swap,
  artistVisible = true,
  marketplace,
  paymentErc20TokenAddress,
  price,
  blockchain,
}: Props) => {
  return (
    <div
      className={twMerge(
        "relative z-10 font-light w-[90%] sm:w-1/2 pl-0 lg:pl-16",
        `${swap ? "md:pl-10" : "lg:pl-20 pr-10"}
       ${
         imageOrientation == ImageOrientation.Portrait
           ? "text-white sm:text-primary m-5 absolute sm:relative"
           : "text-primary"
       } ${
         imageOrientation == ImageOrientation.Landscape
           ? "sm:text-center lg:pl-0 pr-0 pl-0"
           : ""
       }`,
      )}
    >
      <ArtworkItemTradeInfo
        marketplace={marketplace}
        price={price}
        paymentErc20TokenAddress={paymentErc20TokenAddress}
        blockchain={blockchain}
        className={twMerge(
          "font-medium justify-start text-lg",
          imageOrientation == ImageOrientation.Landscape && "lg:justify-center",
        )}
      />
      <Link href={`/nft/${id}`}>
        <h3 className="lg:text-h2-thin mb-2 break-words">{name}</h3>
      </Link>
      {artistVisible ? (
        <UserLink
          artist={artist}
          tooltipPosition={["right", "middle"]}
          className={twMerge(
            "w-fit",
            imageOrientation == ImageOrientation.Landscape && "sm:mx-auto",
          )}
        >
          <div
            className={twMerge(
              " text-body-3 endemic:text-white pl-1",
              `${
                imageOrientation == ImageOrientation.Portrait
                  ? "text-white sm:text-secondary"
                  : "text-secondary"
              }`,
              `${!artistVisible && "lg:hidden block"}`,
            )}
          >
            <EndemicUserWrapper
              id={artist.id}
              className={twMerge(
                imageOrientation == ImageOrientation.Landscape &&
                  "justify-center",
              )}
            >
              by {getUsersName(artist, true)}
            </EndemicUserWrapper>
          </div>
        </UserLink>
      ) : null}
    </div>
  );
};

export default ArtworkItemContent;
